import axios from '@axios'
import { BIconFileEasel } from 'bootstrap-vue'

export default {
  namespaced: true,
  state: {
    loading: true,
    pg: {},
    pgs: [],
    membros: []
  },
  getters: {
    pg: state => {
      return state.pgs
    },
    pgs: state => {
      return state.pgs
    },
    membros: state => {
      return state.membros
    },
    loading: state => {
      return state.loading
    }
  },
  mutations: {
    loading(ctx, loading) {
      ctx.loading = loading
    },
    pg(ctx, pg) {
      ctx.pg = pg
    },
    pgs(ctx, pgs) {
      ctx.pgs = pgs
    },
    membros(ctx, membros) {
      ctx.membros = membros
    }
  },
  actions: {
    fetchPgs(ctx, params) {
      return new Promise((resolve, reject) => {
        ctx.commit('loading', true)
        axios.post('https://reports.renovaremcristo.org.br/renovar-reports/api/executor', {
          "key": "INFORMACOES_DETALHADAS_PGS",
          "params": params
        })
        .then(function(response) {
          ctx.commit('pgs', response.data.data)
          ctx.commit('loading', false)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    fetchMembros(ctx, id) {
      return new Promise((resolve, reject) => {
        ctx.commit('membros', [])
        ctx.commit('loading', true)
        axios.get('https://app.renovaremcristo.org.br/renovar-service/api/pg-members/pg/'+id)
        .then(function(response) {
          ctx.commit('membros', response.data.data)
          ctx.commit('loading', false)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    fetchPg(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.get('https://app.renovaremcristo.org.br/renovar-service/api/pgs/'+id)
        .then(function(response) {
          ctx.commit('pg', response.data.data)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    }
  },
}
