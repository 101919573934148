var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"mt-2 ml-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Pgs")])])],1)],1),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Filtrar..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"pg-filter-select mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"Rede"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('vue-json-to-csv',{attrs:{"json-data":_vm.teste,"labels":{
              pg_name: { title: 'Nome' },
              pg_leaders: { title: 'Lider'},
              supervisor_description: { title: 'Supervisor' },
              network_description: { title: 'Rede'}
            },"csv-title":"Pgs","separator":";"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Download ")])],1)],1)])],1)],1),_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.filterPg(_vm.teste),"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc,"stacked":"sm","per-page":_vm.perPage,"current-page":_vm.currentPage},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(pgStatus)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"cell(pg_id)",fn:function(data){return [_c('b-button',{staticClass:"font-weight-bold",attrs:{"variant":"primary btn-sm","to":{
          name: 'pg-detalhes',
          params: { id: data.item.pg_id },
        }}},[_vm._v(" Detalhes ")])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center justify-content-sm-start\n        ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Exibindo "+_vm._s(_vm.dataMeta.from)+" a "+_vm._s(_vm.dataMeta.to)+" de "+_vm._s(_vm.dataMeta.of)+" pgs")])]),_c('b-col',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center justify-content-sm-end\n        ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalPgs,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }